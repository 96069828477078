import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import TakeoutUrlImage from "../images/backup-guide/1.svg"

const BackupVideosPage = ({ data, location }) => (
  <Layout mainClassName="backup-videos-page">
    <SEO
      title="Backup YouTube Videos"
      description="Backup YouTube videos in your YouTube playlists"
      pathname={location.pathname}
    />
    <section className="intro">
      <div className="inner">
        <div style={{ color: "red", fontWeight: "bold", fontSize: "24px" }}>
          !! Currently not working because of Google Takeout exported data
          change !!
          <br />
          <br />
        </div>
        <h1 className="text__l">
          Learn how to backup your YouTube playlists so you can recover video
          information when it becomes deleted or private
        </h1>
      </div>
    </section>
    <section>
      <div className="angle-start"></div>
      <div className="content">
        <div className="inner">
          <h2 className="text__xl">
            Go to <span>Google Takeout</span>
          </h2>
          <div className="row">
            <div className="col">
              <p>
                Use{" "}
                <a
                  href="https://takeout.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Takeout
                </a>{" "}
                to do the backup. It's official Google service and it's
                completely free.
              </p>
            </div>
            <div className="col">
              <div className="guide-image takeout-url-image">
                <img
                  src={TakeoutUrlImage}
                  alt="Google Takeout url in browser address bar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="angle-end"></div>
    </section>
    <section>
      <div className="inner">
        <h2 className="text__xl">
          Select <span>data to include</span>
        </h2>
        <div className="row">
          <div className="col">
            <p>
              First <strong>Deselect all</strong> products.
            </p>
          </div>
          <div className="col">
            <div className="guide-image">
              <Img
                fluid={data.img2?.childImageSharp?.fluid}
                alt="Google Takeout screenshot 2"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              Scroll down and check only{" "}
              <strong>YouTube and YouTube Music</strong>.<br />
              Click on <strong>All YouTube data included</strong>.
            </p>
          </div>
          <div className="col">
            <div className="guide-image">
              <Img
                fluid={data.img3?.childImageSharp?.fluid}
                alt="Google Takeout screenshot 3"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              Overlay will open, select only <strong>playlists</strong>, confirm
              and overlay will close.
              <br />
              Hit the blue <strong>Next step</strong> button at the end of the
              section.
            </p>
          </div>
          <div className="col">
            <div className="guide-image">
              <Img
                fixed={data.img4?.childImageSharp?.fixed}
                alt="Google Takeout screenshot 4"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="angle-start"></div>
      <div className="content">
        <div className="inner">
          <h2 className="text__xl">
            Create <span>export</span>
          </h2>
          <div className="row">
            <div className="col export-settings">
              <ul>
                <li>
                  <h3 className="text__m">Delivery method</h3>
                  <p>
                    You can download backup archive directly via email link or
                    let it upload to a cloud, it's up to you.
                  </p>
                </li>
                <li>
                  <h3 className="text__m">Frequency</h3>
                  <p>
                    Choose backup frequency. It's quite handy setting automatic
                    export option if every 2 months is enough for you.
                  </p>
                </li>
                <li>
                  <h3 className="text__m">File type &amp; size</h3>
                  <p>You can keep this settings as they are by default.</p>
                </li>
              </ul>
              <p>
                Finally click on <strong>Create export</strong> button.
              </p>
            </div>
            <div className="col">
              <div className="guide-image">
                <Img
                  fluid={data.img5?.childImageSharp?.fluid}
                  alt="Google Takeout screenshot 5"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>
                Creating export is usually pretty fast, about 5 minutes max. You
                can leave the page opened, you will see when it's done or close
                it and wait for email.
              </p>
            </div>
            <div className="col">
              <div className="guide-image">
                <Img
                  fluid={data.img6?.childImageSharp?.fluid}
                  alt="Google Takeout screenshot 6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="angle-end"></div>
    </section>
    <section>
      <div className="inner">
        <h2 className="text__xl">
          Analyze <span>export</span>
        </h2>
        <div className="row">
          <div className="col">
            <p>
              Download and unzip the exported archive. In the{" "}
              <strong>Takeout</strong> folder you can find the{" "}
              <strong>playlists</strong> folder where all your playlists are
              saved.
            </p>
          </div>
          <div className="col">
            <div className="guide-image">
              <Img
                fixed={data.img7?.childImageSharp?.fixed}
                alt="Directory structure of exported archive"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="outro">
      <div className="angle-start"></div>
      <div className="content">
        <div className="inner">
          <p className="text__l">
            From now on, if you find out some YouTube video in your playlist was
            deleted, you can recover video information{" "}
            <Link to="/recover-videos/">here</Link>
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default BackupVideosPage

export const query = graphql`
  query BackupVideosPage {
    img2: file(relativePath: { eq: "backup-guide/2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 479) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img3: file(relativePath: { eq: "backup-guide/3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 479) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img4: file(relativePath: { eq: "backup-guide/4.jpg" }) {
      childImageSharp {
        fixed(width: 253) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    img5: file(relativePath: { eq: "backup-guide/5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 479) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img6: file(relativePath: { eq: "backup-guide/6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 479) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img7: file(relativePath: { eq: "backup-guide/7.jpg" }) {
      childImageSharp {
        fixed(width: 213) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
